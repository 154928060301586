<!-- eslint-disable -->
<template>
<v-container
    id="dashboard"
    fluid
    tag="section"
  >
    <v-row
        align="center"
        justify="center"
    >

        <v-col
            cols="12"
            sm="6"
            lg="3"
            class="text-left"
        >
            <base-material-card
                color="warning"
                class="px-5 py-3"
                title="TANDATANGAN ELEKTRONIK SURAT"
                >
                <table>
                  <tr>
                    <td>
                      <img width="40" :src="linkLogo" />
                    </td>
                    <td>
                      <span class="subtitle-2">KSK GROUP</span>
                      </td>
                  </tr>
                </table>
                <hr>
                <v-row v-if="letterApproval && letterApproval.datasurat">
                  <v-col cols="12" md="9">
                      <table>
                        <tr>
                          <td width="100px;">NO. SURAT</td>
                          <td width="10px;">:</td>
                          <td class="subtitle-2"><span v-if="letterApproval.datasurat">{{ letterApproval.datasurat.noSurat ? letterApproval.datasurat.noSurat : '-' }}</span></td>
                        </tr>
                        <tr>
                          <td>TGL SURAT</td>
                          <td>:</td>
                          <td class="subtitle-2"><span v-if="letterApproval.datasurat">{{ letterApproval.datasurat.tglSurat ? letterApproval.datasurat.tglSurat : '-' | formatDate }}</span></td>
                        </tr>
                        <tr>
                          <td >HAL</td>
                          <td>:</td>
                          <td class="subtitle-2"><span v-if="letterApproval.datasurat">{{ letterApproval.datasurat.perihalSurat ? letterApproval.datasurat.perihalSurat : '-' }}</span></td>
                        </tr>
                      </table>
                  </v-col>
                </v-row>
                <hr>
                 <v-row v-if="letterApproval && letterApproval.datasurat && dataUser && dataPos">
                  <v-col cols="12" md="9">
                    <p><b>TELAH DI TANDATANGAN SECARA ELEKTRONIK OLEH:</b></p>
                      <table>
                        <tr>
                          <td width="100px;">NAMA: </td>
                          <td width="10px;">:</td>
                          <td class="subtitle-2"><span v-if="dataUser">{{ dataUser.fullname ? dataUser.fullname : '-' }}</span></td>
                        </tr>
                        <tr>
                          <td>JABATAN</td>
                          <td>:</td>
                          <td class="subtitle-2"><span v-if="dataPos">{{ dataPos.pos_name ? letterApproval.hruserpositions_to.prefix + ' ' + dataPos.pos_name : '-' }}</span></td>
                        </tr>
                        <tr>
                          <td style="vertical-align:top">TGL</td>
                          <td style="vertical-align:top"> :</td>
                          <td class="subtitle-2"><span v-if="letterApproval.datetime_approval">{{ letterApproval.datetime_approval ? letterApproval.datetime_approval : '-' | formatDate }}</span></td>
                        </tr>
                       
                      </table>
                  </v-col>
                </v-row>
                <hr>
                <v-row>
                  <v-col cols="3" md="3">
                    <div ref="qrcode"></div>
                  </v-col>
                   <v-col class="px-5 py-5 text-center" cols="9" md="9" v-if="letterApproval && letterApproval.datasurat && letterApproval.datasurat.signedSurat">
                     <div v-if="letterApproval.datasurat.eosecuritytype == 2">
                      TIPE SURAT RAHASIA
                     </div>
                     <div v-else>
                       <a v-if="letterApproval.datasurat.signedSurat" :href=" hostBackend + letterApproval.datasurat.signedSurat.url" target="_BLANK">Download</a>
                     </div>
               
                  </v-col>
                </v-row>
            </base-material-card>

        </v-col>
    </v-row>

    <v-snackbar
      v-model="toast.show"
      :timeout="toast.timeout"
    >
      {{ toast.text }}
      <v-btn
        :color="toast.color"
        text
        @click="toast.show = false"
      >
        Close
      </v-btn>
    </v-snackbar>

    <v-dialog v-model="isLoading" fullscreen>
          <v-container fluid fill-height style="background-color: rgba(0, 0, 0, 0.8);">
              <v-layout justify-center align-center>
                <v-card
                  width="70vW"
                  class="pa-md-2 mx-lg-auto"
                  outlined
                >
                  <v-list-item three-line centered>
                    <v-list-item-content>
                      <div class="overline mb-4">Processing</div>
                      <v-list-item-title class="headline mb-1">Please Wait....</v-list-item-title>
                      <v-list-item-subtitle>
                        <v-progress-linear
                          indeterminate
                          color="yellow darken-2"
                        ></v-progress-linear>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>

              </v-layout>
          </v-container>
      </v-dialog>
    
  </v-container>
</template>
<script>
  import moment from 'moment'
  import * as QRCode from 'easyqrcodejs'

  import { _GLOBAL } from '@/api/server'
  import { getlistApprovalByDSignature } from '@/api/letterapproval'
  import { getHRUserPositionsByID } from '@/api/hruserpositions'
  import { getletterGUID } from '@/api/datasurat'
  
  export default {
    name: 'DSignature',
    data() {
      return {
        hostBackend: _GLOBAL.URLBACKEND,
        Dsignature: '',
        toast: { show: false, color:'green', text:'', timeout: 2000 },
        isLoading: false,
        letterguid: '',
        tujuanSurat: null,
        dataUser: null,
        dataPos: null,
        letterApproval: {},
        linkSurat: '',
        linkLogo: ''
      }
    },
    filters: {
      formatDate: function (value) {
        if (!value) return '-'
        var sdate = moment(value).format('DD MMM YYYY')
        return sdate
      },
      formatDatetime: function (value) {
        if (!value) return '-'
        var sdate = moment(value).format('DD/MM/YY HH:mm')
        return sdate
      }
    },
    computed: {

    },
    created (){
      this.key = this.$route.params && this.$route.params.key  
      this.linkLogo = this.hostBackend + "/images/logo_company.png"
    },
     mounted () {
       console.log('dsignature')
       this.getLetterApproval()
    },
    methods:{
      getLetterApproval(){
        const vm = this
        vm.isLoading = true
        // console.log('getLetterApproval', vm.key)

        getlistApprovalByDSignature(vm.key).then(response => {
          var data = response.data
          
          if(data){
            vm.letterApproval = data[0]
            // console.log('letterApproval', vm.letterApproval)
 
            if(vm.letterApproval.hruserpositions_to){

              if(vm.letterApproval.hruserpositions_to.id > 0){
                getHRUserPositionsByID(vm.letterApproval.hruserpositions_to.id).then(resHRUPOS => {
                  // console.log('resHRUPOS', resHRUPOS.data)
                  if(resHRUPOS.data.user){
                    vm.dataUser = resHRUPOS.data.user
                  }
                  if(resHRUPOS.data.hrposition){
                    vm.dataPos = resHRUPOS.data.hrposition
                  }
                })
              }

            }

             if(vm.letterApproval.datasurat){
              getletterGUID(vm.letterApproval.datasurat).then(response => {
                var data = response.data
                // console.log(data)
                vm.letterApproval.datasurat = data[0]
                
              }).catch(err => {
                // console.log(err)
                vm.isLoading = false
              })

              var options = {
                text: vm.hostBackend + vm.letterApproval.datasurat.signedSurat.url,
                logo: vm.linkLogo,
                width: 113,
                height: 113,
                logoWidth: 15,
                logoHeight: 15,
                logoBackgroundColor: '#ffffff',
                logoBackgroundTransparent: false
              }
              // console.log('options', options)
              // Create new QRCode Object
              if(vm.letterApproval.datasurat.eosecuritytype != 2){
                try {
                  var QRC = new QRCode(vm.$refs.qrcode, options);
                } catch (error) {
                  console.log('error', error)
                } 
              }
            }
          }
          vm.isLoading = false
          
        }).catch(err => {
          vm.isLoading = false
        })  
      }
    }
}
</script>
<style>
</style>
<!-- eslint-enable -->